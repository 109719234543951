export const defaults = {
    page_title: "Молдова STOP EU - Телеграм.SITES",
    main_url: "https://tracker.moldova-telegram.com/tracking202/redirect/go.php?acip=915",
    name: "МОЛДОВА STOP EU",
    tag: "@stop_EU",
    main_avatar_url: "/img/1.jpg",
    parsers:[
      {
        name: "Stop EU | Moldova",
        parser: "MOLDOVA_EU",
        tag: "@stop_EU",
        main_url: "https://tracker.moldova-telegram.com/tracking202/redirect/go.php?acip=915",
        avatar_url: "/img/1.jpg",
        contacts_phrase: "Непрочитанные сообщения",
        new_messages: 7
      },
      {
        name: "KP.MD",
        parser: "KP_MOLDOVA",
        tag: "@KpMoldova",
        main_url: "https://tracker.moldova-telegram.com/tracking202/redirect/go.php?acip=824",
        avatar_url: "/img/2.jpg",
        contacts_phrase: "Непрочитанные сообщения",
        new_messages: 6
      },
      {
        name: "Stop Media Ban",
        parser: "MEDIA_BAN",
        tag: "@stopmediaban",
        main_url: "https://tracker.moldova-telegram.com/tracking202/redirect/go.php?acip=235",
        avatar_url: "/img/3.jpg",
        contacts_phrase: "Непрочитанные сообщения",
        new_messages: 5
      },
      {
        name: "TV6",
        parser: "TV6",
        tag: "@stv6md",
        main_url: "https://tracker.moldova-telegram.com/tracking202/redirect/go.php?acip=541",
        avatar_url: "/img/4.jpg",
        contacts_phrase: "Непрочитанные сообщения",
        new_messages: 4
      },
      {
        name: "Blocul Politic Victorie",
        parser: "VICTORIE",
        tag: "@victorie_blocul",
        main_url: "https://tracker.moldova-telegram.com/tracking202/redirect/go.php?acip=651",
        avatar_url: "/img/5.jpg",
        contacts_phrase: "Непрочитанные сообщения",
        new_messages: 3
      },
    ],
    posts: []
  }
